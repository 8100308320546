.star-toggle {
  font-size: 10pt;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
  margin-top: -4px;
}

.star-toggle:hover {
  cursor: pointer;
}

.star-toggle-empty {
  color: lightgray;
}

.star-toggle-full {
  color: orange;
}
