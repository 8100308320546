.status-icon {
  width: 8px;
  height: 8px;
  margin-left: 1px;
  margin-right: 2px;
  border-radius: 50%;
  display: inline-block;
}

.status-icon-live {
  background-color: limegreen;
}

.status-icon-obsolete {
  border: 1px solid limegreen;
}

.status-icon-outdated {
  border: 1px solid gray;
}
