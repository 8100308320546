.prefix {
  font-size: 80%;
}

.kmk-id {
  font-variant: tabular-nums;
  letter-spacing: -0.5px;
  padding: 0 1px;
  display: inline-block;
}
