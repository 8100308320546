.favorite-stop-toggle {
  font-size: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
  margin-top: -3px;
}

.favorite-stop-toggle:hover {
  cursor: pointer;
}

.favorite-stop-toggle-empty {
  color: lightgray;
}

.favorite-stop-toggle-full {
  color: orange;
}
