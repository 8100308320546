body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

th,
strong,
.bold,
.fw-bold {
  font-weight: 600 !important;
}

.nobr {
  white-space: nowrap;
}

.hidden-link {
  text-decoration: none;
  color: inherit !important;
}

.hidden-link:hover {
  text-decoration: underline;
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-td-align-middle td {
  vertical-align: middle;
}

.table-text-center th,
.table-text-center td {
  text-align: center !important;
}
