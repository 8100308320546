.live-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: limegreen;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: -13px;
}
