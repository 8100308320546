.blink {
  animation: 0.9s linear infinite blink;
}

@keyframes blink {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
