.box {
  font-weight: 600;
  letter-spacing: -0.5pt;
  display: inline-block;
  width: 22pt;
  height: 21pt;
  line-height: 19pt;
  text-align: center;
  color: black;
  border: 1px solid black;
  background: transparent;
}

.box.tram {
  font-size: 11.5pt;
}

.box.bus {
  font-size: 10.5pt;
}

.box.large {
  width: 23pt;
  height: 22pt;
  line-height: 20pt;
  font-size: 11pt;
  font-weight: 500;
}

.box.eco {
  color: green;
  background: transparent;
  border-color: green;
}

.box.detour {
  color: black;
  background: yellow;
  border-color: black;
}

.box.night {
  color: white;
  background: black;
  border-color: black;
}

.box.night.detour {
  color: yellow;
  background: black;
  border-color: black;
}

.box.cemetery {
  background: rgb(60, 130, 250);
  color: white;
  border-color: transparent;
}
