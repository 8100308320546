.timetable-hour {
  width: 30px;
}

.timetable-minutes {
  font-variant: tabular-nums;
  letter-spacing: -0.3;
}

.timetable-scroll-anchor {
  position: relative;
  top: -80px;
}

.route-short-name {
  font-size: 45pt;
  font-weight: 700;
  line-height: 120%;
}

.agglomeration-route {
  font-size: 10pt;
  letter-spacing: -0.5px;
}
