.low-floor {
  background: #55bb3340;
}

.low-entry {
  background: #ffc10740;
}

.high-floor {
  background: #dc354540;
}

.unknown-floor {
  background: #6c757d40;
}
